export const teamData = [
    {
        slug: 'ross-belhomme',
        name: 'Ross Belhomme',
        image: 'https://media.licdn.com/dms/image/v2/D5622AQEPSZXl4t6MKg/feedshare-shrink_2048_1536/feedshare-shrink_2048_1536/0/1726734169117?e=2147483647&v=beta&t=aTvUXnd8ap8sK3hEQmRCVJOmMYQXMyKWwOrgZm41PhM',
        title: 'Founder',
        company: 'Orbilaw',
        specialty: 'International Tax & Structuring Expert',
        website: 'www.orbilaw.com',
        email: 'ross@belhommelaw.com',
        phone: '+41 78 914 80 09',
        whatsapp: 'https://wa.me/41787477386',
        addresses: [
            '6 Rue Jean-Francois-Bartholoni, Genève 1204, Switzerland',
        ],
        addressLink: 'https://www.google.com/maps/search/6%20Rue%20Jean-Francois-Bartholoni%2C%20Gen%C3%A8ve%201204%2C%20Switzerland%C2%A0',
        linkedIn: 'rossbelhomme',
        handle: 'rbelhume'
    },
    {
        slug: 'maxime-billen',
        name: 'Maxime Billen',
        image: 'https://maximebillen.com/assets/images/image01.jpg?v=279d3f88',
        title: 'Co-Founder & Client Manager',
        company: 'Orbilaw',
        specialty: 'Improve UX & Optimize Internal Resources',
        website: 'www.orbilaw.com',
        email: 'maxime@belhommelaw.com',
        phone: '+41 78 747 73 86',
        whatsapp: 'https://wa.me/41787477386',
        addresses: [
            'Place de la Gare 5, Lausanne 1006, Switzerland',
        ],
        addressLink: 'https://maps.app.goo.gl/V2Eg54da4uT2auFP9?g_st=com.google.maps.preview.copy',
        linkedIn: 'maximebillen',
        handle: 'maxbillen'
    },
    {
        slug: 'deniz-karatas',
        name: 'Deniz Karatas',
        image: 'https://arweave.net/VzK2agrDb7dJsg2Q5nGYBWvR0ni1a58O_miKLVoW1uU?ext=png',
        title: 'Co-Founder & Project Leader',
        company: 'Orbilaw',
        specialty: 'Full Stack Dev & Data Analyst',
        website: 'www.orbilaw.com',
        email: 'deniz@belhommelaw.com',
        phone: '+32 467 80 35 65',
        addresses: [
            'Melle, Ghent 9000, Belgium ',
        ],
        linkedIn: 'deniz-karatas',
        handle: 'moneyparamedic'
    },
]

