import React from 'react';
import { useParams, Navigate } from 'react-router-dom';
import mappings from './qrMappings.json';

const QRRedirect = () => {
    const { slug } = useParams();
    const destination = mappings[slug] || '/';

    return <Navigate to={destination} replace />;
};

export default QRRedirect;
