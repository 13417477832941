import React from "react";
import './landing.css';
import { useNavigate } from "react-router-dom";

function Landing() {
    const navigate = useNavigate();

    return (
        <section id='landing' className="landing">
            <div className="landing-elements">
                <div className="landing-title">Orbilaw</div>
                <div className="powered-by" onClick={() => window.open('https://www.belhommelaw.com/', "_blank")}>Powered By Belhomme Law</div>
                <div className="landing-middle">Powering Web3 projects with legal excellence</div>
                <button onClick={() => navigate('/services')} className="landing-button">
                    Secure my project
                </button>
            </div>


        </section>
    );
}

export default Landing;
