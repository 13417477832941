// Home.jsx

import React from 'react';
import Landing from './sections/Landing/Landing.jsx';
import Introduction from './sections/Introduction/Introduction.jsx';
import About from './sections/About/About.jsx';
import Clients from './sections/Clients/Clients.jsx';
import './home.css';
import Spline from '@splinetool/react-spline';
import WhyUs from './sections/WhyUs/WhyUs.jsx';
function Home() {
    return (
        <div className='main'>
            <div className='spline-resize'>
                {/* <Spline className="spline-scene-home" scene="https://prod.spline.design/8KVQV84BUBO4ODVt/scene.splinecode" /> */}
            </div>
            <section id='landing'>
                <Landing />
            </section>
            <section id='introduction'>
                <Introduction />
            </section>
            <section id='about' >
                <About />
            </section>
            {/* <section id='whyus'>
                <WhyUs />
            </section> */}
            <section id='clients'>
                <Clients />
            </section>
        </div>
    );
}

export default Home;
