// AppRoutes.jsx

import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import Home from '../Pages/Home/Home';
import Contact from '../Pages/Contact/Contact';
import Orbiwiki from '../Pages/Orbiwiki/Orbiwiki';
import Services from '../Pages/Services/Services';
import TeamCard from '../components/TeamCard/TeamCard';
import QRRedirect from '../QRRedirect';

const AppRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/orbiwiki" element={<Orbiwiki />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/services" element={<Services />} />
            <Route path="/team/:personName" element={<TeamCard />} />

            {/* This route catches /qr/<slug> */}
            <Route path="/qr/:slug" element={<QRRedirect />} />

            {/* Catch-all route */}
            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    );
};

export default AppRoutes;
