// TeamMember.jsx
import React from 'react';
import { useParams } from 'react-router-dom';
import { teamData } from './TeamData';
import BusinessCard from './BusinessCard';

const TeamMember = () => {
    const { personName } = useParams();
    const person = teamData.find(member => member.slug === personName);

    return <BusinessCard person={person} />;
};

export default TeamMember;
