import React from 'react';
import './businesscard.css';

const BusinessCard = ({ person }) => {
    if (!person) {
        return <div className="not-found">Team member not found.</div>;
    }

    return (
        <div className="card-wrapper">
            <div className="card">
                <div className="card-image">
                    <img src={person.image} alt={person.name} />
                </div>

                {/* Name and branding block */}
                <div className="card-info">
                    <div className="card-brand">
                        <h2 className="brand-name">
                            Orbilaw
                        </h2>
                        <h3 className="person-name">{person.name}</h3>
                        <p className="founder-title">{person.title}</p>
                    </div>

                    <p className="tagline">{person.specialty}</p>

                    <div className="contact-list">
                        <div className="contact-item">
                            <div className="contact-icon">
                                <img src="https://cdn-icons-png.flaticon.com/128/3178/3178162.png" alt="" />
                            </div>
                            <a
                                href={`https://${person.website}`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {person.website}
                            </a>
                        </div>

                        {/* Email */}
                        <div className="contact-item">
                            <div className="contact-icon">
                                <img src="https://cdn-icons-png.flaticon.com/128/3178/3178158.png" alt="" />
                            </div>
                            <a href={`mailto:${person.email}`}>{person.email}</a>
                        </div>

                        {/* Phone */}
                        <div className="contact-item">
                            <div className="contact-icon">
                                <img src="https://cdn-icons-png.flaticon.com/128/4494/4494470.png" alt="" />
                            </div>
                            <a
                                href={`https://wa.me/41789148009`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <span>{person.phone}</span>
                            </a>
                        </div>

                        {/* Addresses */}
                        {person.addresses.map((address, index) => (
                            <div className="contact-item" key={index}>
                                <div className="contact-icon">
                                    <img src="https://cdn-icons-png.flaticon.com/128/5616/5616461.png" alt="" />
                                </div>
                                <a
                                    href={`${person.addressLink}`}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <span>{address}</span>
                                </a>
                            </div>
                        ))}

                        {/* LinkedIn */}
                        <div className="contact-item">
                            <div className="contact-icon">
                                <img src="https://cdn-icons-png.flaticon.com/128/3669/3669739.png" alt="" />
                            </div>
                            <a
                                href={`https://www.linkedin.com/in/${person.linkedIn}/`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <span>{person.linkedIn}</span>
                            </a>
                        </div>

                        {/* Handle */}
                        <div className="contact-item">
                            <div className="contact-icon">
                                <img src="https://cdn-icons-png.flaticon.com/128/2111/2111710.png" alt="" />
                            </div>
                            <a
                                href={`https://telegram.me/${person.telegram}`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <span>{person.handle}</span>
                            </a>
                        </div>
                    </div>

                    <button className="save-contact">{`Let ${person.name.split(' ')[0]} contact you!`}</button>
                </div>
            </div>
        </div>
    );
};

export default BusinessCard;
