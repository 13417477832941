import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import OrbilawImg from '../../assets/images/Orbilaw.png'
import './navbar.css';

const Navbar = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isScrolled, setIsScrolled] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 0);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        // Prevent body scroll when menu is open
        if (isMenuOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [isMenuOpen]);

    const handleNavigation = (path, sectionId = null) => {
        setIsMenuOpen(false);

        if (location.pathname !== path) {
            navigate(path);
            if (sectionId) {
                setTimeout(() => {
                    const section = document.getElementById(sectionId);
                    if (section) section.scrollIntoView({ behavior: 'smooth' });
                }, 300);
            }
        } else if (sectionId) {
            const section = document.getElementById(sectionId);
            if (section) section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <nav className={`navbar ${isScrolled ? 'navbar-scrolled' : 'navbar-top'}`}>
            <div className="mobile-header">
                <div className="mobile-logo">
                    <img src={OrbilawImg} alt="Orbilaw" className='orbilaw-navbar-logo' />
                </div>
                <button
                    className={`hamburger ${isMenuOpen ? 'open' : ''}`}
                    onClick={() => setIsMenuOpen(!isMenuOpen)}
                    aria-expanded={isMenuOpen}
                    aria-label="Toggle navigation menu"
                >
                    <div className="bar"></div>
                    <div className="bar"></div>
                    <div className="bar"></div>
                </button>
            </div>

            {/* Desktop Navigation */}
            <ul className="nav-list">
                <li><button onClick={() => handleNavigation('/', 'landing')}>Home</button></li>
                <li><button onClick={() => handleNavigation('/', 'about')}>About</button></li>
                <li><button onClick={() => handleNavigation('/orbiwiki')}>Orbiwiki</button></li>
                <li><button onClick={() => handleNavigation('/services')}>Our Services</button></li>
                <li><button onClick={() => handleNavigation('/contact')}>Contact Us</button></li>
            </ul>

            {/* Mobile Navigation Overlay */}
            <div className={`mobile-nav-overlay ${isMenuOpen ? 'open' : ''}`}>
                <button
                    className="close-menu"
                    onClick={() => setIsMenuOpen(false)}
                    aria-label="Close navigation menu"
                >
                </button>

                <ul className="mobile-nav-list">
                    <li><button onClick={() => handleNavigation('/', 'landing')}>Home</button></li>
                    <li><button onClick={() => handleNavigation('/', 'about')}>About</button></li>
                    <li><button onClick={() => handleNavigation('/orbiwiki')}>Orbiwiki</button></li>
                    <li><button onClick={() => handleNavigation('/services')}>Our Services</button></li>
                    <li><button onClick={() => handleNavigation('/contact')}>Contact Us</button></li>
                </ul>
            </div>
        </nav>
    );
};

export default Navbar;