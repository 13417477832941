import React from 'react';
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';

import ScrollToTop from './components/ScrollToTop.jsx';
import AppRoutes from './Routes/Routes.jsx';
import Navbar from './components/Navbar/Navbar.jsx';
import Footer from './components/Footer/Footer.jsx';
import { useLocation } from 'react-router-dom';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <AppLayout />
    </Router>
  );
}
function AppLayout() {
  const location = useLocation();

  const hideNavbar = location.pathname === '/orbiwiki' || location.pathname.includes('/team')

  return (
    <div className="website-background">
      <div className="background-container">
        <div id="stars"></div>
        <div id="stars2"></div>
        <div id="stars3"></div>
        <div className="website-main-elements">
          {!hideNavbar && <Navbar />}
          <AppRoutes />
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default App;
